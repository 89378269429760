<template>
  <div>
    <b-row class="justify-content-md-center" v-if="logged_in">
      <b-col col lg="6" md="auto" align-self="center">
        <b-card>
          <b-card-title>
            Events
            <EnableNotifications size="sm" class="float-right" :locate="locateSubscription" :onSubscribe="onSubscribe" :onUnsubscribe="onUnsubscribe"></EnableNotifications>
          </b-card-title>
          <b-list-group v-if="events.length != 0">
            <b-list-group-item
              v-for="event in events"
              :key="event.id"
              class="d-flex justify-content-between align-items-center"
              v-b-modal="'answer-' + event.id"
              button
            >
              <HumanDate :date="event.created_at" />
              <b-badge
                v-if="unansweredQuestions(event).length != 0"
                variant="primary"
                pill
                >Incomplete: {{ unansweredQuestions(event).length }}</b-badge
              >
              <b-badge v-else variant="light" pill>Completed</b-badge>
              <AnswerQuestions :modal_id="'answer-' + event.id" :event="event" @save="saveAnswers"></AnswerQuestions>
            </b-list-group-item>
          </b-list-group>
          <h4 style="text-align: center" v-else>No events</h4>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HumanDate from "../../components/HumanDate.vue";
import EnableNotifications from "../../components/EnableNotifications.vue";
import AnswerQuestions from "../../components/AnswerQuestions.vue";

export default {
  name: "AccountEvents",
  data() {
    return {
      columns: [
        {
          key: "link",
          label: "",
        },
        {
          key: "created_at",
          label: "Event Time",
        },
      ],
      events: [],
      timer: null,
    };
  },
  methods: {
    onSubscribe(source, data) {
      return this.user.enableNotifications(source, data)
    },
    onUnsubscribe(source, data) {
      return this.user.disableNotifications(source, data)
    },
    locateSubscription(source, data) {
      return this.user.locateSubscription(source, data)
    },
    saveAnswers(e) {
      var answer_results = [];

      for(var j = 0; j < e.answers.length; j++) {
        let answer = e.answers[j]
        answer_results.push(e.event.answer(answer.answer.id, answer.value))
      }

      Promise.all(answer_results).then(
        () => {
          e.hide();
          e.clearErrors()

          return this.fetchEvents()
        },
        (errors) => e.errors(errors)
      )
    },
    fetchEvents() {
      if (this.logged_in) {
        var _this = this;
        this.user
          .events(true, { params: { include: ["answers", "answers.question", "answers.question.group"] } })
          .then((events) => {
            _this.events = events;
          });
      }
    },
    unansweredQuestions(event) {
      var unanswered = [];
      if (event._loaded_answers && event._loaded_answers.length != 0) {
        for (var j = 0; j < event._loaded_answers.length; j++) {
          if (!event._loaded_answers[j].answered) {
            unanswered.push(event._loaded_answers);
          }
        }
      }

      return unanswered;
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    init(){
      this.fetchEvents();
      this.timer = setInterval(this.fetchEvents, 5000);
    }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  computed: {
    ...mapGetters(["user", "logged_in"]),
  },
  components: { HumanDate, EnableNotifications, AnswerQuestions },
  mounted() {
    window.addEventListener('introspectator:userLoaded', this.init)

    if(this.logged_in) {
      this.init();
    }
  },
  destroyed() {
    window.removeEventListener('introspectator:userLoaded', this.init)
  }
};
</script>