<template>
  <span v-if="!validDate">Invalid Date: {{ date }}</span>
  <span v-else-if="showDate">{{ humanDate }}</span>
  <span v-else :title="humanDate">{{ timeAgo }}</span>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
var localizedFormat = require("dayjs/plugin/localizedFormat");

export default {
  name: "HumanDate",
  props: ["date"],
  created() {
    dayjs.extend(relativeTime);
    dayjs.extend(localizedFormat);
  },
  computed: {
    validDate() {
      if (!this.date || this.date == null || this.date == undefined) {
        return false;
      }

      return true;
    },
    _date() {
      if (!this.validDate) {
        return null;
      }

      var timezone = "";

      if (this.date.indexOf("+") == -1) {
        timezone = "Z";
      }

      return dayjs(this.date + timezone);
    },
    showDate() {
      if (!this.validDate) {
        return true;
      }

      var weekAgo = dayjs().subtract(7, "days");

      if (this._date.isBefore(weekAgo)) {
        return true;
      }

      return false;
    },
    humanDate() {
      return this._date.format("L LT");
    },
    timeAgo() {
      return this._date.fromNow();
    },
  },
};
</script>