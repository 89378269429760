<template>
    <div>
        <span v-if="answer.question.type == 'text'">{{ answer.answered ? answer.value : default_answer }}</span>
        <b-form-select
            v-else-if="answer.question.type == 'selection'"
            :options="answer.question.choices"
            :value="answer.value ? answer.value : []"
            :multiple="answer.question.metadata && answer.question.metadata.multiple"
            :select-size="answer.question.metadata && answer.question.metadata.size ? answer.question.metadata.size : 1"
            disabled
        ></b-form-select>
        <b-card-group deck v-else-if="answer.question.type == 'radio'">
            <b-card v-for="choice in answer.question.choices" :key="choice" :bg-variant="selected_choice(choice) ? 'primary' : 'secondary'" text-variant="white" class="text-center" :data-selected="selected_choice(choice)">
                <b-card-text>{{ choice }}</b-card-text>
            </b-card>
        </b-card-group>
        <b-card-group deck v-else-if="answer.question.type == 'check'">
            <b-card v-for="choice in answer.question.choices" :key="choice" :bg-variant="selected_choice(choice) ? 'primary' : 'secondary'" text-variant="white" class="text-center" :data-selected="selected_choice(choice)">
                <b-card-text>{{ choice }}</b-card-text>
            </b-card>
        </b-card-group>
    </div>
  </template>
  
  <script>
  export default {
    name: "Answer",
    props: ["answer"],
    computed: {
        default_answer() {
            return "[Unanswered]"
        },
    },
    methods: {
        selected_choice(choice) {
            if(this.answer.question.type == 'radio') {
                return this.answer.answered && this.answer.value == choice
            }

            if(this.answer.question.type == 'check') {
                return this.answer.answered && this.answer.value.indexOf(choice) != -1
            }

            return false;
        }
    }
  };
  </script>