<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col col lg="6" md="auto" align-self="center">
        <b-card no-body>
          <b-card-body>
            <b-card-title>
              My Account
              <b-button-group class="float-right">
                <b-button
                  variant="light"
                  size="sm"
                  :to="{
                    name: 'account/settings/modify',
                  }"
                >
                  Modify
                </b-button>
              </b-button-group>
            </b-card-title>
            <b-table stacked :items="[user]" :fields="fields"></b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountSettings",
  data() {
    return {
      fields: ["name", "email"],
    };
  },
  computed: {
    ...mapGetters(["user", "logged_in"]),
  },
};
</script>