<template>
  <div>
    <h1>Welcome!</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped>
h1 {
  text-align: center;
}
</style>