<template>
    <b-button v-b-modal="modal_id" v-bind="$attrs">
        <slot name="button">Confirm</slot>
        <b-modal
            :id="modal_id"
            ref="modal"
            @hidden="handleCancel"
            @ok="handleOk"
        >
            <template #modal-title>
                <slot name="title">Are you sure?</slot>
            </template>
            <slot name="body"></slot>
            <b-alert v-for="error in errors" show variant="danger" :key="error">
                {{ error }}
            </b-alert>
        </b-modal>
    </b-button>
</template>
  
  <script>
  export default {
    name: "ConfirmationModal",
    props: ["modal_id"],
    emit: ["confirm", "cancel"],
    inheritAttrs: false,
    data() {
      return {
        errors: [],
      };
    },
    methods: {
      handleOk(modal) {
        this.$emit('confirm', modal);
      },
      handleCancel(modal) {
        this.$emit('cancel', modal);
      },
    },
  };
  </script>