<template>
    <div>
        <b-form-group
            v-if="question.type == 'text'"
            :label="question.question"
            ::label-for="'answer-input-' + question.id"
            invalid-feedback="Question is required"
            :state="answerState"
        >
            <b-form-input
                :id="'answer-input-' + question.id"
                v-model="answerValue"
                :state="answerState"
                required
            ></b-form-input>
        </b-form-group>
        <b-form-group
            v-else-if="question.type == 'selection'"
            :label="question.question"
            :label-for="'answer-input-' + question.id"
            invalid-feedback="Question is required"
            :state="answerState"
        >
            <b-form-select
                :id="'answer-input-' + question.id"
                v-model="answerSelection"
                :options="question.choices"
                :state="answerSelectionState"
                :multiple="question.metadata && question.metadata.multiple"
                :select-size="question.metadata && question.metadata.size ? question.metadata.size : 1"
                required
            ></b-form-select>
        </b-form-group>
        <b-form-group
            v-else-if="question.type == 'radio'"
            :label="question.question"
            :label-for="'answer-input-' + question.id"
            invalid-feedback="Question is required"
            :state="answerState"
        >
            <b-card-group deck>
                <b-card v-for="choice in question.choices" :key="choice" :bg-variant="selected_choice(choice) ? 'primary' : 'secondary'" text-variant="white" class="text-center" @click="radioSelect" :data-value="choice" :data-selected="selected_choice(choice)">
                    <b-card-text>{{ choice }}</b-card-text>
                </b-card>
            </b-card-group>
        </b-form-group>
        <b-form-group
            v-else-if="question.type == 'check'"
            :label="question.question"
            :label-for="'answer-input-' + question.id"
            invalid-feedback="Question is required"
            :state="answerState"
        >
            <b-card-group deck>
                <b-card v-for="choice in question.choices" :key="choice" :bg-variant="selected_choice(choice) ? 'primary' : 'secondary'" text-variant="white" class="text-center" @click="checkSelect" :data-value="choice" :data-selected="selected_choice(choice)">
                    <b-card-text>{{ choice }}</b-card-text>
                </b-card>
            </b-card-group>
        </b-form-group>
    </div>
  </template>
  
  <script>
  export default {
    name: "QuestionInput",
    props: ["question", "answer"],
    data() {
      return {
        answerValue: this.answer && this.answer.answered ? this.answer.value : '',
        answerState: null,
        answerSelection: this.answer && this.answer.answered ? this.answer.value : [],
        answerSelectionState: null,
      };
    },
    computed: {
        value() {
            if(this.question.type == "text" && this.answer && (this.answer.answered && this.answer.value != this.answerValue || !this.answer.answered)) {
                return this.answerValue
            }
            if(this.question.type == "selection" && this.answer && (this.answer.answered && this.answer.value != this.answerSelection || !this.answer.answered)) {
                return this.answerSelection;
            }
            if(this.question.type == "radio" && this.answer && (this.answer.answered && this.answer.value != this.answerValue || !this.answer.answered)) {
                return this.answerValue;
            }
            if(this.question.type == "check" && this.answer && (this.answer.answered && this.answer.value != this.answerSelection || !this.answer.answered)) {
                return this.answerSelection;
            }

            return undefined;
        },
        valid() {
            if(this.question.type == "text") {
                return this.value != ""
            }
            if(this.question.type == "selection") {
                return this.value.length != 0;
            }
            if(this.question.type == "radio") {
                return this.value != "";
            }
            if(this.question.type == "check") {
                return this.value.length != 0;
            }

            return false;
        }
    },
    methods: {
        radioSelect(e) {
            var card = e.target.closest('.card');
            var value = card.dataset.value;
            var deck = e.target.closest('.card-deck');

            for(var j = 0; j < deck.childNodes.length; j++) {
                var child = deck.childNodes[j];

                if(child === card) {
                    child.classList.add('bg-primary');
                    child.classList.remove('bg-secondary');
                } else {
                    child.classList.remove('bg-primary');
                    child.classList.add('bg-secondary');
                }
            }

            this.answerValue = value;
        },
        checkSelect(e) {
            var card = e.target.closest('.card');
            var deck = e.target.closest('.card-deck');

            var value = [];

            for(var j = 0; j < deck.childNodes.length; j++) {
                var child = deck.childNodes[j];

                if(child === card) {
                    if(child.dataset.selected != "true") {
                        child.dataset.selected = "true";

                        child.classList.add('bg-primary');
                        child.classList.remove('bg-secondary');
                    } else {
                        child.dataset.selected = "false";

                        child.classList.remove('bg-primary');
                        child.classList.add('bg-secondary');
                    }
                }

                if(child.dataset.selected == "true") {
                    value.push(child.dataset.value);
                }
            }

            this.answerSelection = value;
        },
        selected_choice(choice) {
            if(this.question.type == 'radio') {
                return this.answer.answered && this.answer.value == choice
            }

            if(this.question.type == 'check') {
                return this.answer.answered && this.answer.value.indexOf(choice) != -1
            }

            return false;
        }
    },
  };
  </script>