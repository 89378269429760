<template>
  <div id="app">
    <AppHeader></AppHeader>
    <AppBody></AppBody>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppHeader from './layout/AppHeader.vue'
import AppBody from './layout/AppBody.vue'
import AppFooter from './layout/AppFooter.vue'

import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppBody,
    AppFooter
  },
  methods: {
    update_user() {
      this.refresh_api_user().then((user) => console.log(user.name, "logged in", user.id)).catch((error) => console.log("error", error))
    },
    ...mapActions(['refresh_api_user'])
  },
  mounted() {
    window.addEventListener('vuexoidc:userLoaded', this.update_user)
  },
  destroyed() {
    window.removeEventListener('vuexoidc:userLoaded', this.update_user)
  }
}
</script>

<style lang="scss">
body,
html {
  background-color: $fgColor;
}
</style>
