<template>
  <div>
    <h1 style="text-align: center;">About Us</h1>
  </div>
</template>

<script>
export default {
  name: 'AboutUS'
}
</script>
