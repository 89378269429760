<template>
  <div class="footer">
    <b-container toggleable="lg" type="dark" variant="dark">
      <b-row>
        <b-col>
          Intro Spectator<hr />
          <a href="#">About Us</a><br />
          <a href="#">Contact Us</a><br />
          <a href="#">More Links</a><br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang="scss">
.footer {
  padding: 10px;
  color: #fff;
}
a {
  color: #fff;
}
</style>
