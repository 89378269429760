<template>
  <div v-if="logged_in">
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="6" align-self="center">
        <b-card v-if="!group_edit.enabled">
          <b-card-title>
            Group
            <b-button-group v-if="group_found" class="float-right">
              <b-button variant="light" size="sm" @click="enable_group_edit">
                Modify
              </b-button>
            </b-button-group>
          </b-card-title>
          <b-table v-if="group_found" stacked :items="[group]" :fields="group_headers">
            <template #cell(created_at)="data">
              <HumanDate :date="data.value" />
            </template>
          </b-table>
          <b-container v-else>
            <span v-if="group_loading">Loading...</span>
            <h4 v-else class="text-center">Not Found</h4>
          </b-container>
        </b-card>
        <b-card v-else>
          <b-form @submit="submitGroupEdit">
            <b-card-title>
              Group
              <b-button-group class="float-right">
                <b-button type="submit" variant="info" size="sm">
                  Save
                </b-button>
                <b-button variant="danger" size="sm" @click="disable_group_edit">
                  Cancel
                </b-button>
              </b-button-group>
            </b-card-title>
            <b-table-simple>
              <b-tbody>
                <b-tr>
                  <b-th>Name</b-th>
                  <b-td>
                    <b-form-input id="group_edit_name" v-model="group_edit.name" placeholder="Enter Name"
                      required></b-form-input>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th>Created</b-th>
                  <b-td>
                    <HumanDate :date="group.created_at" />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-form>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" align-self="center">
        <b-card no-body>
          <b-card-body>
            <b-card-title>
              Organization
              <b-button v-if="organization_found" variant="info" class="float-right" size="sm" :to="{
                name: 'account/organization',
                params: { organization_id: organization.id },
              }">View</b-button>
            </b-card-title>
            <b-table v-if="organization_found" stacked :items="[organization]" :fields="organization_headers">
              <template #cell(created_at)="data">
                <HumanDate :date="data.value" />
              </template>
            </b-table>
            <b-container v-else>
              <span v-if="organization_loading">Loading...</span>
              <h4 v-else class="text-center">Not Found</h4>
            </b-container>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="10" align-self="center">
        <b-card no-body>
          <b-card-body>
            <b-card-title>
              Members
              <GroupAddMember v-if="group != null" :group="group" size="sm" text="Add" class="float-right"
                v-bind:open="loadMembers" v-bind:submit="submitAddMember"></GroupAddMember>
            </b-card-title>
            <b-list-group v-if="users && users.length != 0">
              <div class="d-flex w-100 justify-content-between" v-for="user in users" :key="user.id">
                <ConfirmationModal :modal_id="user.id" variant="outline-danger" squared size="sm" @confirm.prevent="removeMember(user)">
                  <template #button>X</template>
                  <template #title>Remove user?</template>
                  <template #body>
                    Are you sure you want to remove <b-badge>{{ user.name }}</b-badge>?
                  </template>
                </ConfirmationModal>
                <span style="width: 100%" class="list-group-item d-flex justify-content-between align-items-center">
                  {{ user.name }}
                </span>
              </div>
            </b-list-group>
            <b-container v-else>
              <span v-if="group_loading">Loading...</span>
              <h4 v-else class="text-center">No members</h4>
            </b-container>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="10" align-self="center">
        <b-card no-body>
          <b-card-body v-if="questions && questions.length > 0">
            <b-card-title>
              Questions
              <NewQuestion class="float-right" size="sm" text="New" v-bind:submit="submitNewQuestion"></NewQuestion>
            </b-card-title>
            <b-list-group v-if="questions && questions.length != 0">
              <div class="d-flex w-100 justify-content-between" v-for="question in questions" :key="question.id">
                <ConfirmationModal :modal_id="'remove-'+question.id" variant="outline-danger" squared size="sm" @confirm.prevent="deleteGroupQuestion(question)">
                  <template #button>X</template>
                  <template #title>Remove question?</template>
                  <template #body>
                    Are you sure you want to remove <b-badge>{{ question.question }}</b-badge>?
                  </template>
                </ConfirmationModal>
                <span style="width: 100%" class="list-group-item d-flex justify-content-between align-items-center">
                  {{ question.question }}
                  <span>
                    <b-badge variant="primary" pill>
                      {{ question.type }}
                    </b-badge>
                  </span>
                </span>
                <UpdateQuestion :question="question" variant="outline-warning" squared size="sm" @submit="event => updateGroupQuestion(event)">
                </UpdateQuestion>
              </div>
            </b-list-group>
          </b-card-body>
          <b-card-body v-else>
            <h4 v-if="group_loading">Loading questions...</h4>
            <b-container v-else class="text-center">
              <h4>No questions</h4>
              <NewQuestion v-bind:submit="submitNewQuestion"></NewQuestion>
            </b-container>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewQuestion from "../../components/NewQuestion.vue";
import HumanDate from "../../components/HumanDate.vue";
import GroupAddMember from "../../components/GroupAddMember.vue";
import ConfirmationModal from "../../components/ConfirmationModal.vue";
import UpdateQuestion from "../../components/UpdateQuestion.vue";

export default {
  name: "AccountOrganizationGroup",
  data() {
    return {
      group_edit: {
        enabled: false,
        name: null,
      },
      group_loading: true,
      group: null,
      group_headers: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "created_at",
          label: "Created",
        },
      ],
      organization_loading: true,
      organization: null,
      organization_headers: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "created_at",
          label: "Created",
        },
      ],
      users: null,
      member_headers: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "created_at",
          label: "Assigned",
        },
        {
          key: "answers",
          label: "Answers",
        },
      ],
      questions: null,
      question_headers: [
        {
          key: "actions",
          label: "",
        },
        {
          key: "question",
          label: "Question",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "created_at",
          label: "Created",
        },
      ],
    };
  },
  methods: {
    async fetchOrg() {
      if (this.logged_in) {
        var org = await this.user.organization(
          this.$route.params.organization_id
        );
        this.organization = org;
        this.organization_loading = false;
      }
    },
    async fetchGroup() {
      if (this.logged_in) {
        var group = await this.organization.group(this.$route.params.group_id, {
          params: {
            include: ["users", "questions"],
          },
        });
        if (!group.id) {
          this.group_loading = false;
          return;
        }
        this.group = group;
        this.users = group.users;
        this.questions = group.questions;
        this.group_loading = false;
        return this.group;
      }
    },
    async submitNewQuestion(values) {
      return new Promise((resolve, reject) => {
        values.group_id = this.group.id;
        this.organization
          .createQuestion({
            json: values,
          })
          .then(() => {
            this.fetchGroup().then(resolve);
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
    },
    enable_group_edit() {
      if (this.group == null || this.group_loading) {
        return;
      }
      this.group_edit.name = this.group.name;
      this.group_edit.enabled = true;
    },
    disable_group_edit() {
      this.group_edit.enabled = false;
    },
    group_edit_values() {
      return {
        name: this.group_edit.name,
      };
    },
    async submitGroupEdit() {
      return new Promise((resolve, reject) => {
        this.organization
          .groupUpdate(this.group.id, this.group_edit_values())
          .then(() => {
            this.fetchGroup().then(() => {
              this.disable_group_edit();
            });
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
    },
    async loadMembers() {
      return new Promise((resolve, reject) => {
        this.organization
          .users()
          .then((users) => {
            var user_emails = [];
            for (var j = 0; j < users.length; j++) {
              user_emails.push(users[j].email);
            }
            resolve(user_emails);
          })
          .catch(reject);
      });
    },
    async submitAddMember(result) {
      return new Promise((resolve, reject) => {
        this.organization
          .inviteToGroup(this.group.id, result.member)
          .then((user) => {
            this.fetchGroup();
            resolve(user);
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
    },
    updateGroupQuestion(event) {
      this.organization
        .updateGroupQuestion(this.group.id, event.question.id, event.updates)
        .then(() => {
          this.fetchGroup();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteGroupQuestion(question) {
      this.organization
        .deleteGroupQuestion(this.group.id, question.id)
        .then(() => {
          this.fetchGroup();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeMember(user) {
      this.organization
        .removeGroupMember(this.group.id, user.id)
        .then(
          () => {
            this.$bvModal.hide(user.id);

            this.fetchGroup();
          },
          (errors) => {
            console.log(errors);
          }
        )
    },
    init() {
      this.fetchOrg().then(() => {
        this.fetchGroup();
      });
    },
  },
  computed: {
    group_found() {
      return this.group != null;
    },
    organization_found() {
      return this.organization != null;
    },
    ...mapGetters(["user", "logged_in"]),
  },
  components: { NewQuestion, HumanDate, GroupAddMember, ConfirmationModal, UpdateQuestion },
  mounted() {
    window.addEventListener('introspectator:userLoaded', this.init)

    if(this.logged_in) {
      this.init();
    }
  },
  destroyed() {
    window.removeEventListener('introspectator:userLoaded', this.init)
  }
};
</script>