<template>
  <div>
    <b-button variant="info" :size="size" v-b-modal="modal_id">{{
      text || "Add Member"
    }}</b-button>
    <b-modal
      :id="modal_id"
      ref="modal"
      :title="title || 'Add member to ' + group.name"
      @show="handleShow"
      @hidden="reset"
      @ok="handleOk"
    >
      <form ref="add_group_member" @submit.stop.prevent="handleSubmit">
        <b-form-select
          v-model="addMember"
          :options="member_list"
          :state="memberState"
          required
        ></b-form-select>
      </form>
      <b-alert v-if="submit_error" show variant="danger"
        >Error: {{ error }}</b-alert
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "GroupAddMember",
  props: ["group", "members", "size", "open", "submit", "text", "title"],
  data() {
    return {
      member_list: [],
      opening: true,
      addMember: "",
      memberState: null,
      error: null,
    };
  },
  computed: {
    modal_id() {
      return "group-add-member-" + this.group.id;
    },
    submit_error() {
      return (this.error && this.error != "") || false;
    },
  },
  methods: {
    checkFormValidity() {
      this.memberState = this.addMember != "";
      return this.memberState;
    },
    reset() {
      this.opening = true;
      this.member_list = [];
      this.addMember = "";
      this.memberState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleShow() {
      this.reset();

      if (this.members && this.members.length != 0) {
        this.member_list = this.members;
      }

      if (this.open) {
        this.open()
          .then((members) => {
            this.member_list = members;
            this.opening = false;
          })
          .catch((err) => {
            this.error = err;
            this.opening = false;
          });
      } else {
        this.opening = false;
      }
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.submit({
        group_id: this.group.id,
        member: this.addMember,
      })
        .then(() => {
          this.error = null;
          this.$nextTick(() => {
            this.$bvModal.hide(this.modal_id);
          });
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
};
</script>