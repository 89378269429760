<template>
    <b-modal :id="modal_id" ref="modal" :title="current_group ? current_group.name : 'Event Questions'" ok-only @show="reset">
        <form ref="answer_questions" @submit.stop.prevent="save">
            <div v-for="group in groups" :key="group.id">
                <div :hidden="group != current_group">
                    <QuestionInput v-for="answer in group.answers" :key="answer.id" :ref="answer.id" :question="answer.question" :answer="answer"></QuestionInput>
                </div>
            </div>
            <b-alert v-for="(error, idx) in errors" :key="idx" show variant="danger">
                {{ error }}
            </b-alert>
        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button v-if="current_group !== first_group" variant="primary" size="sm" @click="previousGroup">Back</b-button>
                <b-button-group class="float-right">
                    <b-button v-if="current_group !== last_group" variant="primary" size="sm" @click="nextGroup">Next</b-button>
                    <b-button v-else variant="primary" size="sm" @click="save">Save</b-button>
                </b-button-group>
            </div>
        </template>
    </b-modal>
</template>

<script>
import QuestionInput from "./QuestionInput.vue";

export default {
    name: "AnswerQuestions",
    props: ["modal_id", "event"],
    emits: ["save"],
    data() {
        return {
            errors: [],
            grouped: {},
            groups: [],
            current_group: null,
            first_group: null,
            last_group: null,
        };
    },
    methods: {
        previousGroup() {
            var current = this.groups.indexOf(this.current_group);

            if(current > 0) {
                this.current_group = this.groups[current-1];
            }
        },
        nextGroup() {
            var current = this.groups.indexOf(this.current_group);

            if(current < this.groups.length) {
                this.current_group = this.groups[current+1];
            }
        },
        answer_values() {
            let answer_values = [];

            for(var j = 0; j < this.groups.length; j++){
                let group = this.groups[j];
                for(var k = 0; k < group.answers.length; k++){
                    let answer = group.answers[k];

                    let input = this.$refs[answer.id][0];
                    if(!input) {
                        console.log("Missing answer input reference:", answer);

                        continue;
                    }

                    if(input.value === undefined) {
                        console.log("answer value undefined", answer);

                        continue;
                    }

                    answer_values.push({
                        answer: answer,
                        value: input.value,
                    })
                }
            }

            return answer_values;
        },
        reset() {
            this.errors = [];
            this.grouped = {};
            this.groups = [];
            this.current_group = null;
            this.first_group = null;
            this.last_group = null;

            if (this.event === null || !this.event._loaded_answers) {
                return;
            }

            for (let j = 0; j < this.event._loaded_answers.length; j++) {
                let answer = this.event._loaded_answers[j];
                let group = answer.question.group;

                if (!(group.id in this.grouped)) {
                    if(this.groups.length == 0) {
                        this.first_group = group;
                        this.current_group = group;
                    }

                    group.answers = [];

                    this.grouped[group.id] = group;
                    this.groups.push(group);

                    this.last_group = group;
                }

                this.grouped[group.id].answers.push(answer);
            }
        },
        save() {
            this.$emit('save', {
                event: this.event,
                answers: this.answer_values(),
                hide: () => this.$refs.modal.hide(),
                errors: (errors) => this.errors = errors ? errors : [],
                clearErrors: () => this.errors = [],
            })
        },
    },
    components: { QuestionInput },
};
</script>