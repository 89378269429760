<template>
    <div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'OidcCallback',
    methods: {
        handleCallback(redirectPath) {
            this.refresh_api_user().then(() => this.$router.push(redirectPath)).catch((error) => console.log("error", error));
        },
        ...mapActions([
            'oidcSignInCallback',
            'refresh_api_user'
        ])
    },
    mounted() {
        this.oidcSignInCallback()
            .then((redirectPath) => {
                this.handleCallback(redirectPath)
            }).catch((err) => {
                console.error(err)
                this.$router.push('/login/error') // Handle errors any way you want
            })
    }
}
</script>
