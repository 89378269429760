import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { vuexOidcCreateStoreModule } from 'vuex-oidc-ts'

import { oidcSettings } from './config/oidc'

import {
    IntroSpectator
} from './assets/js/introspectator/api.js'

export default new Vuex.Store({
    modules: {
        oidcStore: vuexOidcCreateStoreModule(oidcSettings, {
            isAuthenticatedBy: 'access_token',
            publicRoutePaths: ['/', '/about-us', '/login'],
            dispatchEventsOnWindow: true
        })
    },
    state: {
        api: new IntroSpectator(process.env.VUE_APP_MUNC_URL),
        user: null,
        route: null
    },
    mutations: {
        update_user(state, user) {
            state.user = user
        },
        SET_ROUTE(state, route) {
            state.route = route;
        }
    },
    getters: {
        user(state) {
            return state.user
        },
        api(state) {
            return state.api
        },
        username(state, getters) {
            if (!getters.logged_in) {
                return 'guest';
            }
            return state.user.name;
        },
        logged_in(state) {
            if (state.user && state.user.id && state.oidcStore && state.oidcStore.access_token) {
                return true;
            }
            return false;
        }
    },
    actions: {
        login({ state, dispatch }) {
            return new Promise(() => {
                dispatch('authenticateOidc', state.route.fullpath);
            });
        },
        logout({
            commit, dispatch
        }) {
            return new Promise((resolve) => {
                dispatch('signOutOidc')
                commit('update_user', null)
                resolve()
            });
        },
        update_user({
            commit
        }, user) {
            return new Promise((resolve) => {
                commit('update_user', user)
                window.dispatchEvent(new CustomEvent("introspectator:userLoaded", {
                    detail: {
                        user: user
                    }
                }))
                resolve()
            })
        },
        refresh_api_user({ state, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                if (getters.oidcIsAuthenticated) {
                    state.api.v1.login(getters.oidcAccessToken).then((user) => {
                        dispatch('update_user', user)

                        resolve(user)
                    }).catch((error) => {
                        reject(error)
                    })
                } else {
                    reject('not logged in')
                }
            })
        }
    }
})
