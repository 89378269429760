<template>
    <b-button v-b-modal="modal_id" v-bind="$attrs">
        <slot name="button">
            <b-icon-pencil-square></b-icon-pencil-square>
        </slot>
        <b-modal
            :id="modal_id"
            ref="modal"
            :name="modal_id"
            @ok="save"
        >
            <template #modal-title>
                <slot name="title">Edit {{ group.name }}</slot>
            </template>
            <form :id="form_id" :name="form_id" @submit.stop.prevent="save">
                <b-form-group
                    :id="form_id + '-order'"
                    description="Set the ordering weight for this group"
                    label="Order"
                    :label-for="form_id + '-order-input'"
                    :state="updates.state"
                >
                    <b-form-input :id="form_id + '-order-input'" type="number" min="0" v-model.number="updates.order" trim></b-form-input>
                </b-form-group>
                <b-alert v-for="error in errors" show variant="danger" :key="error">
                    {{ error.msg }}
                </b-alert>
            </form>
        </b-modal>
    </b-button>
  </template>
  
  <script>
  export default {
    name: "UpdateGroup",
    props: ["group"],
    emit: ["save"],
    inheritAttrs: false,
    data() {
        return {
            updates: {
                order: this.group.order,
            },
            errors: []
        }
    },
    computed: {
        modal_id() {
            return 'modal-edit-group-' + this.group.id;
        },
        form_id() {
            return 'form-edit-group-' + this.group.id;
        },
    },
    methods: {
      save() {
        var event = {
            group: this.group,
            updates: {
                order: this.updates.order,
            },
            hide: () => this.$refs.modal.hide(),
            errors: (errors) => this.errors = errors ? errors : [],
            clearErrors: () => this.errors = [],
        }

        this.$emit('save', event);
      },
    }
  };
  </script>