<template>
  <div>
    <b-row v-if="logged_in" class="justify-content-md-center">
      <b-col col lg="6" md="auto" align-self="center">
        <b-card>
          <b-card-title>
            Organizations
          </b-card-title>
          <b-list-group v-if="organizations.length != 0">
            <b-list-group-item
              v-for="org in organizations"
              :key="org.id"
              :to="{
                name: 'account/organization',
                params: { organization_id: org.id },
              }"
              class="d-flex justify-content-between align-items-center"
            >
              {{ org.name }}
              <b-badge
                v-if="org._loaded_users && org._loaded_users.length != 0"
                variant="primary"
                pill
                >{{ org._loaded_users.length }}</b-badge
              >
              <b-badge v-else variant="light" pill>0</b-badge>
            </b-list-group-item>
          </b-list-group>
          <b-container v-else>
            <span v-if="orgs_loaded">Loading...</span>
            <h4 v-else class="text-center">No orgs</h4>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountOrganizations",
  data() {
    return {
      orgs_loaded: false,
      organizations: [],
      timer: null,
    };
  },
  methods: {
    fetchOrgs() {
      if (this.logged_in) {
        var _this = this;
        this.user
          .organizations(true, { params: { include: "users" } })
          .then((organizations) => {
            _this.organizations = organizations;
            _this.orgs_loaded = true;
          });
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    init() {
      this.fetchOrgs();
      this.timer = setInterval(this.fetchOrgs, 5000);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  computed: {
    ...mapGetters(["user", "logged_in"]),
  },
  mounted() {
    window.addEventListener('introspectator:userLoaded', this.init)

    if(this.logged_in) {
      this.init();
    }
  },
  destroyed() {
    window.removeEventListener('introspectator:userLoaded', this.init)
  }
};
</script>