<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col col lg="6" md="auto" align-self="center">
        <b-form @submit="submitUpdate">
          <b-card no-body>
            <b-card-body>
              <b-card-title>
                My Account
                <b-button-group class="float-right">
                  <b-button type="submit" variant="info" size="sm">
                    Save
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    :to="{
                      name: 'account/settings',
                    }"
                  >
                    Cancel
                  </b-button>
                </b-button-group>
              </b-card-title>
              <b-alert
                variant="danger"
                show
                v-for="err in errors"
                :key="err.msg"
              >
                {{ err.msg }}
              </b-alert>
              <b-table-simple>
                <b-tbody>
                  <b-tr>
                    <b-th>Name</b-th>
                    <b-td>
                      <b-form-input
                        id="user_name"
                        v-model="user_name"
                        placeholder="Enter Name"
                        required
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Email</b-th>
                    <b-td>
                      <b-form-input
                        id="user_email"
                        v-model="user_email"
                        type="email"
                        placeholder="Enter email"
                        required
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AccountSettingModify",
  data() {
    return {
      user_set: false,
      user_name: "",
      user_email: "",
      timer: null,
      errors: [],
    };
  },
  methods: {
    ...mapActions(["update_user"]),
    async setUser() {
      if (!this.user_set && this.user) {
        this.user_name = this.user.name;
        this.user_email = this.user.email;
        this.user_set = true;
      }
      if (this.user_set && this.timer === null) {
        clearInterval(this.timer);
      }
    },
    async submitUpdate(event) {
      event.preventDefault();
      return new Promise((resolve) => {
        var updates = {};
        if (!this.sameName) {
          updates.name = this.user_name;
        }
        if (!this.sameEmail) {
          updates.email = this.user_email;
        }
        if (updates.length == 0) {
          console.log("No changes, skipping");
          resolve();
          return;
        }

        this.user
          .update(updates)
          .then((user) => {
            this.update_user(user);
            resolve(user);
            var settingsRoute = this.$router.resolve({
              name: "account/settings",
            });
            this.$router.push(settingsRoute.route.fullPath);
          })
          .catch((errs) => {
            this.errors = errs;
          });
      });
    },
    init() {
      this.setUser();
      this.timer = setInterval(this.setUser, 100);
    },
  },
  computed: {
    ...mapGetters(["user", "logged_in"]),
    sameName() {
      return this.user.name === this.user_name;
    },
    sameEmail() {
      return this.user.email === this.user_email;
    },
  },
  mounted() {
    window.addEventListener('introspectator:userLoaded', this.init)

    if(this.logged_in) {
      this.init();
    }
  },
  destroyed() {
    window.removeEventListener('introspectator:userLoaded', this.init)
  }
};
</script>