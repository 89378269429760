<template>
        <b-modal :id="modal_id" ref="modal" :title="'Member ' + user.name + ' events'" ok-only
            @show="handleShow">
            <div v-if="event === null">
                <b-list-group v-if="events !== null && events.length != 0">
                    <b-list-group-item button v-for="event in events" :key="event.id" @click="showEvent(event)"
                        class="d-flex justify-content-between align-items-center">
                        <HumanDate :date="event.created_at" />
                        <b-badge v-if="unansweredQuestions(event).length != 0" variant="primary" pill>Incomplete: {{
                            unansweredQuestions(event).length }}</b-badge>
                        <b-badge v-else variant="light" pill>Completed</b-badge>
                    </b-list-group-item>
                </b-list-group>
                <span style="text-align: center" v-else-if="error !== null">{{ error }}</span>
                <span style="text-align: center" v-else-if="events === null">loading...</span>
                <h4 style="text-align: center" v-else>No events</h4>
            </div>
            <div v-else>
                <div v-if="event_answers && event_answers.length > 0">
                    <div v-for="(group_answers, group) in grouped_answers" :key="group">
                        <h4>{{ group }}</h4>
                        <b-card v-for="answer in group_answers" :key="answer.id" class="mb-2"
                            :border-variant="answer.answered ? 'success' : 'warning'"
                            :header="answer.question.question">
                            <b-card-text>
                                <Answer :answer="answer"></Answer>
                            </b-card-text>
                        </b-card>
                    </div>
                </div>
                <div v-else>
                    <h4 class="text-center">No questions</h4>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <b-button v-if="event !== null" variant="primary" size="sm" @click="hideEvent">Back</b-button>
                </div>
            </template>
        </b-modal>
</template>

<script>
import Answer from "./Answer.vue";
import HumanDate from "./HumanDate.vue";

export default {
    name: "OrganizationMemberAnswers",
    props: ["modal_id", "user", "open", "text", "size"],
    data() {
        return {
            events: null,
            error: null,
            event: null
        };
    },
    computed: {
        event_answers() {
            return this.event !== null ? this.event._loaded_answers : null;
        },
        grouped_answers() {
            if (this.event === null) {
                return {};
            }

            let grouped = {};
            for (let j = 0; j < this.event_answers.length; j++) {
                let group = this.event_answers[j].group;

                if (!(group.name in grouped)) {
                    grouped[group.name] = [];
                }

                grouped[group.name].push(this.event_answers[j]);
            }

            return grouped;
        },
    },
    methods: {
        unansweredQuestions(event) {
            var unanswered = [];
            if (event._loaded_answers && event._loaded_answers.length != 0) {
                for (var j = 0; j < event._loaded_answers.length; j++) {
                    if (!event._loaded_answers[j].answered) {
                        unanswered.push(event._loaded_answers);
                    }
                }
            }

            return unanswered;
        },
        handleShow() {
            this.events = null;
            this.error = null;
            this.event = null;

            this.open(this.user.id).then((events) => {
                this.error = null;
                this.events = events
            })
                .catch((err) => {
                    this.error = err;
                    this.events = null;
                })
        },
        showEvent(event) {
            this.event = event;
        },
        hideEvent() {
            this.event = null;
        }
    },
    components: { HumanDate, Answer },
};
</script>