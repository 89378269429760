<template>
    <b-button v-b-modal="modal_id" v-bind="$attrs">
        <slot name="button">
            <b-icon-pencil-square></b-icon-pencil-square>
        </slot>
        <b-modal
            :id="modal_id"
            ref="modal"
            :name="modal_id"
            @ok="handleOk"
        >
            <template #modal-title>
                <slot name="title">Edit Question</slot>
            </template>
            <form :id="form_id" :name="form_id" @submit.stop.prevent="handleOk">
                <b-form-group
                    :id="form_id + '-order'"
                    description="Set the ordering weight for this question"
                    label="Order"
                    :label-for="form_id + '-order-input'"
                    :state="updates.state"
                >
                    <b-form-input :id="form_id + '-order-input'" type="number" min="0" v-model.number="updates.order" trim></b-form-input>
                </b-form-group>
                <b-alert v-for="error in errors" show variant="danger" :key="error">
                    {{ error.msg }}
                </b-alert>
            </form>
        </b-modal>
    </b-button>
  </template>
  
  <script>
  export default {
    name: "UpdateQuestion",
    props: ["question"],
    emit: ["submit"],
    inheritAttrs: false,
    data() {
        return {
            updates: {
                order: this.question.order,
            },
            errors: []
        }
    },
    computed: {
        modal_id() {
            return 'modal-edit-question-' + this.question.id;
        },
        form_id() {
            return 'form-edit-question-' + this.question.id;
        },
    },
    methods: {
      handleOk() {
        var event = {
            question: this.question,
            updates: {
                order: this.updates.order,
            }
        }

        this.$emit('submit', event);
      },
    }
  };
  </script>