<template>
  <div id="nav">
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-container>
        <b-navbar-brand href="#">
          <img src="@/assets/imgs/brand.png" class="d-inline-block align-top" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{name: 'home'}">Home</b-nav-item>
            <b-nav-item :to="{name: 'about-us'}">About Us</b-nav-item>
            <UserNav></UserNav>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <UserSubNav></UserSubNav>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

import UserNav from '../components/UserNav.vue'
import UserSubNav from '../components/UserSubNav.vue'

export default {
  name: 'HeaderNavigation',
  components: {
    UserNav,
    UserSubNav,
  },
  computed: {
    ...mapGetters([
      'username',
      'logged_in'
    ])
  }
}
</script>

<style lang="scss">
  #nav {
    background-color: $fgColor;
  }
  .navbar-brand > img {
    height: 50px;
  }
  #subnav {
    background-color: $fgColor;
    padding: 10px;
    a {
      margin-left: 5px;
    }
  }
</style>
