<template>
  <div v-if="logged_in">
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="6" align-self="center">
        <b-card title="Event">
          <b-table
            v-if="event"
            stacked
            :items="[event]"
            :fields="event_headers"
          >
            <template #cell(created_at)="data">
              <HumanDate :date="data.value" />
            </template>
          </b-table>
          <b-container v-else>
            <span v-if="event_loading">Loading...</span>
            <h4 v-else class="text-center">Not Found</h4>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="10" align-self="center">
        <h2 class="text-center">Questions</h2>
        <b-card no-body>
          <b-card-body v-if="answers && answers.length > 0">
            <div
              v-for="(group_answers, group) in answers_by_group"
              :key="group"
            >
              <b-card-title> {{ group }} </b-card-title>
              <b-table
                :items="group_answers"
                :fields="answer_headers"
                thead-class="d-none"
              >
                <template #cell(question)="data">
                  {{ data.value.question }}
                </template>
                <template #cell(links)="data">
                  <AnswerQuestion
                    v-if="!data.item.answered"
                    class="float-right"
                    size="sm"
                    text="Answer"
                    :question="data.item.question"
                    :answer_id="data.item.id"
                    :submit="submitNewAnswer"
                  ></AnswerQuestion>
                  <b-button v-else variant="light" disabled size="sm"
                    >Answered</b-button
                  >
                </template>
              </b-table>
            </div>
          </b-card-body>
          <b-card-body v-else>
            <h4 v-if="event_loading">Loading questions...</h4>
            <h4 class="text-center">No questions</h4>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AnswerQuestion from "../../components/AnswerQuestion.vue";
import HumanDate from "../../components/HumanDate.vue";

export default {
  name: "AccountEvent",
  data() {
    return {
      event_loading: true,
      event: null,
      event_headers: [
        {
          key: "created_at",
          label: "Time",
        },
      ],
      answers: null,
      answer_headers: [
        {
          key: "question",
          label: "",
        },
        {
          key: "links",
          label: "",
        },
      ],
      answers_by_group: {},
    };
  },
  methods: {
    update_answer_groups() {
      this.answers_by_group = {};
      for (var j = 0; j < this.answers.length; j++) {
        var group = this.answers[j].question.group;
        if(!group) {
          continue
        }

        if (!(group.name in this.answers_by_group)) {
          this.answers_by_group[group.name] = [];
        }
        this.answers_by_group[group.name].push(this.answers[j]);
      }
    },
    async fetchEvent() {
      if (this.logged_in) {
        var event = await this.user.event(this.$route.params.event_id, {
          params: {
            include: ["answers", "answers.question", "answers.question.group"],
          },
        });
        if (!event.id) {
          this.event_loading = false;
          return;
        }
        this.event = event;
        this.answers = event._loaded_answers;
        this.event_loading = false;
        this.update_answer_groups();
        return this.event;
      }
    },
    async submitNewAnswer(resp) {
      return new Promise((resolve, reject) => {
        this.event
          .answer(resp.answer_id, resp.value)
          .then(() => {
            this.fetchEvent().then(resolve);
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
    },
  },
  computed: {
    ...mapGetters(["user", "logged_in"]),
  },
  components: { AnswerQuestion, HumanDate },
  mounted() {
    window.addEventListener('introspectator:userLoaded', this.fetchEvent)

    if(this.logged_in) {
      this.fetchEvent();
    }
  },
  destroyed() {
    window.removeEventListener('introspectator:userLoaded', this.fetchEvent)
  }
};
</script>