<template>
    <div>
      <b-row class="justify-content-md-center">
        <b-col col lg="6" md="auto" align-self="center">
            <b-card>
                <b-card-title>
                    Members
                    <b-button-group class="float-right">
                        <AddMember size="sm" text="Add" v-bind:submit="submitAddMember"></AddMember>
                    </b-button-group>
                </b-card-title>
                <b-list-group v-if="users && users.length != 0">
                    <b-list-group-item v-for="user in users" :key="user.id" class="d-flex justify-content-between align-items-center">
                    {{ user.email }}
                    <span>
                        <b-badge variant="primary" pill>{{ user.name }}</b-badge>
                    </span>
                    </b-list-group-item>
                </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
import AddMember from "../../components/AddMember.vue";
  
  export default {
    name: "Members",
    data() {
      return {
        fields: ["name", "email"],
        users: null,
      };
    },
    computed: {
      ...mapGetters(["user", "logged_in", "api"]),
    },
    methods: {
        async fetchUsers() {
            if (this.logged_in) {
                this.users = await this.api.v1.admin.users.all();

                return this.users;
            }
        },
        async submitAddMember(name, email) {
            return new Promise((resolve, reject) => {
                this.api.v1.admin.users.create(name, email)
                .then(
                    () => this.fetchUsers(),
                    reject,
                )
                .then(resolve)
            });
        },
        init() {
            this.fetchUsers();
        },
    },
    mounted() {
        window.addEventListener('introspectator:userLoaded', this.init)

        if(this.logged_in) {
        this.init();
        }
    },
    destroyed() {
        window.removeEventListener('introspectator:userLoaded', this.init)
    },
    components: { AddMember }
  };
  </script>