<template>
  <div id="app-body">
    <b-container toggleable="lg" type="light">
      <b-row>
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
export default {
  name: 'AppBody'
}
</script>

<style lang="scss">
#app-body {
  background-color: $bgColor;
}
#app-body > div {
  background-color: white;
}
</style>