<template>
  <div>
    <b-button variant="info" :size="size" v-b-modal="modal_id">{{
      text || "Add Member"
    }}</b-button>
    <b-modal
      :id="modal_id"
      ref="modal"
      :title="title || 'Add member to ' + organization.name"
      @show="handleShow"
      @hidden="reset"
      @ok="handleOk"
    >
      <form ref="add_organization_member" @submit.stop.prevent="handleSubmit">
        <b-form-group label="User" label-for="email-input" invalid-feedback="User required" :state="memberState">
          <b-form-select
            v-model="memberEmail"
            :options="member_list"
            :state="memberState"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Level" label-for="level-input" invalid-feedback="Level required" :state="memberState">
          <b-form-select v-model="memberLevel" :options="['admin', 'user']" :state="memberState" required></b-form-select>
        </b-form-group>
      </form>
      <b-alert v-if="submit_error" show variant="danger"
        >Error: {{ error }}</b-alert
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "OrganizationAddMember",
  props: ["organization", "members", "size", "open", "submit", "text", "title"],
  data() {
    return {
      member_list: [],
      opening: true,
      memberEmail: "",
      memberLevel: "",
      memberState: null,
      error: null,
    };
  },
  computed: {
    modal_id() {
      return "organization-add-member-" + this.organization.id;
    },
    submit_error() {
      return (this.error && this.error != "") || false;
    },
  },
  methods: {
    checkFormValidity() {
      this.memberState = this.memberEmail != "" && this.memberLevel != "";
      return this.memberState;
    },
    reset() {
      this.opening = true;
      this.member_list = [];
      this.memberEmail = "";
      this.memberLevel = "";
      this.memberState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleShow() {
      this.reset();

      if (this.members && this.members.length != 0) {
        this.member_list = this.members;
      }

      if (this.open) {
        this.open()
          .then((members) => {
            this.member_list = members;
            this.opening = false;
          })
          .catch((err) => {
            this.error = err;
            this.opening = false;
          });
      } else {
        this.opening = false;
      }
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.submit({
        organization_id: this.organization.id,
        member: this.memberEmail,
        level: this.memberLevel
      })
        .then(() => {
          this.error = null;
          this.$nextTick(() => {
            this.$bvModal.hide(this.modal_id);
          });
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
};
</script>
