<template>
  <div>
    <b-button variant="info" :size="size" v-b-modal.new-question>{{
      text || "New Question"
    }}</b-button>
    <b-modal
      id="new-question"
      ref="modal"
      title="New Question"
      @show="resetNewQuestion"
      @hidden="resetNewQuestion"
      @ok="handleOk"
    >
      <form ref="new_question" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Question"
          label-for="question-input"
          invalid-feedback="Question is required"
          :state="questionState"
        >
          <b-form-input
            id="question-input"
            v-model="question"
            :state="questionState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Type"
          label-for="type-input"
          invalid-feedback="Type is required"
          :state="typeState"
        >
          <b-form-select
            v-model="questionType"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="is_list"
          label="Options"
          label-for="option-input"
          invalid-feedback="Option is required"
          :state="optionState"
        >
          <b-form-input
            v-for="(val, idx) in questionOptions"
            :key="idx"
            v-model="questionOptions[idx]"
            placeholder="Enter Option"
            required
          ></b-form-input>
          <div class="clearfix">
            <b-button
              class="float-right"
              variant="info"
              size="sm"
              @click="addOption"
              >Add Option</b-button
            >
          </div>
        </b-form-group>
        <b-form-group
          v-if="questionType == 'selection'"
          label="Allow multiple selections?"
          label-for="multi-selection"
        >
          <b-form-checkbox
            id="multi-selection"
            v-model="questionOptionMultiSelection"
            value="true"
            unchecked-value="false"
          >
            Allow multiple options to be selected
          </b-form-checkbox>
        </b-form-group>
      </form>
      <b-alert v-if="submit_error" show variant="danger"
        >Error: {{ error }}</b-alert
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "NewQuestion",
  props: ["submit", "size", "text"],
  data() {
    return {
      questionType: "",
      typeState: null,
      question: "",
      questionState: null,
      options: [
        {
          text: "Text",
          value: "text"
        }, {
          text: "Selection",
          value: "selection"
        }, {
          text: "Single select",
          value: "radio"
        }, {
          text: "Multi select",
          value: "check"
        }
      ],
      questionOptions: [""],
      optionState: null,
      questionOptionMultiSelection: "false",
      error: null,
    };
  },
  computed: {
    is_list() {
      return this.questionType == "selection" || this.questionType == "radio" || this.questionType == "check"
    },
    submit_error() {
      return (this.error && this.error != "") || false;
    },
    populatedQuestionOptions() {
      var count = 0;
      for (var j = 0; j < this.questionOptions.length; j++) {
        if (this.questionOptions[j] != "") {
          count++;
        }
      }
      return count;
    },
  },
  methods: {
    checkFormValidity() {
      this.typeState = this.questionType != "";
      this.questionState = this.question != "";
      this.optionState = this.is_list && this.populatedQuestionOptions != 0;

      return this.typeState && this.questionState;
    },
    addOption() {
      this.questionOptions.push("");
    },
    resetNewQuestion() {
      this.questionType = "";
      this.typeState = null;
      this.question = "";
      this.questionState = null;
      this.optionState = null;
      this.questionOptions = [""];
      this.questionOptionMultiSelection = "false";
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    getQuestion() {
      var question = {
        question: this.question,
        type: this.questionType,
      };

      if (this.is_list) {
        question.choices = this.questionOptions;

        if(this.questionType == 'selection') {
          question.metadata = {
            size: Math.min(5, question.choices.length),
            multiple: this.questionOptionMultiSelection == "true",
          }
        }
      }

      return question;
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.submit(this.getQuestion())
        .then(() => {
          this.error = null;
          this.$nextTick(() => {
            this.$bvModal.hide("new-question");
          });
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
};
</script>