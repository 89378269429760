<template>
  <b-nav-item-dropdown right v-if="logged_in">
    <template slot="button-content">Account</template>
    <div class="d-block d-lg-none">
      <b-dropdown-item :to="{ name: 'account/events' }">Events</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'account/organizations' }">Organizations</b-dropdown-item>
      <b-dropdown-item :to="{ name: 'admin/members' }">Members</b-dropdown-item>
    </div>
    <b-dropdown-item :to="{ name: 'account/settings' }">Settings</b-dropdown-item>
    <b-dropdown-item @click="createEvent">Create Event</b-dropdown-item>
    <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
  </b-nav-item-dropdown>
  <b-nav-item v-else @click="login">Login</b-nav-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserNav",
  methods: {
    login() {
      console.log("dispatching login")
      this.$store.dispatch("login")
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    createEvent() {
      if (this.logged_in) {
        this.$store.state.api.v1.admin.users.createEvent(
          this.$store.state.api.v1.user.id
        );
      }
    },
  },
  computed: {
    ...mapGetters(["username", "logged_in"]),
  },
};
</script>