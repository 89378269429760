<template>
    <div>
        <b-dropdown text="Notifications" size="sm" variant="outline-dark" right>
            <b-dropdown-item v-if="webpush.found == false || webpush.state != 'granted'" :disabled="webpush.found && webpush.state != 'prompt'" @click="enableWebPush">
                Enable Push
            </b-dropdown-item>
            <b-dropdown-item v-else-if="webpush.found !== null" :disabled="webpush.state != 'granted'" @click="disableWebPush">
                Disable Push
            </b-dropdown-item>
            <b-dropdown-item v-if="sms.found == false" v-b-modal.enable-sms>
                Enable SMS
            </b-dropdown-item>
            <b-dropdown-item v-else-if="sms.found !== null" @click="disableSMS">
                Disable SMS
            </b-dropdown-item>
        </b-dropdown>
        <b-modal id="enable-sms" ref="modal" title="Enable SMS" @ok="enableSMS">
            <form id="enable-sms" @submit.stop.prevent="enableSMS">
                <b-form-group
                    id="enable-sms-number"
                    description="Enter SMS number to subscribe."
                    label="Phone Number"
                    label-for="enable-sms-number"
                    valid-feedback="Thank you!"
                    :invalid-feedback="sms_invalid_feedback"
                    :state="sms.state"
                >
                <b-form-input id="enable-sms-number" v-model="sms.to" :state="sms.state" trim></b-form-input>
                </b-form-group>
                <b-alert v-for="error in sms.errors" show variant="danger" :key="error">
                    {{ error.msg }}
                </b-alert>
            </form>
        </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    name: "EnableNotifications",
    props: ["size", "locate", "onSubscribe", "onUnsubscribe"],
    data() {
        return {
            webpush: {
                state: null,
                found: null,
            },
            sms: {
                found: null,
                to: null,
                errors: []
            }
        }
    },
    computed: {
        sms_state() {
            return this.sms.to && this.sms.to.length == 10
        },
        sms_invalid_feedback() {
            if(!this.sms.to) {
                return 'Please enter a phone number'
            }
            if(this.sms.to.length != 10) {
                return 'Please enter a 10 digit phone number'
            }

            return 'Unknown error'
        }
    },
    methods: {
        refreshWebPush() {
            var _this = this;

            return new Promise((resolve) => {
                navigator.permissions.query({
                    name: 'notifications'
                }).then(function(result) {
                    _this.webpush.state = result.state;

                    resolve(result);
                })
            })
        },
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/');

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
        async enableWebPush() {
            var _this = this;
            return navigator.serviceWorker
                .register('/service-worker.js')
                .then(function (registration) {
                    const subscribeOptions = {
                        userVisibleOnly: true,
                        applicationServerKey: _this.urlBase64ToUint8Array(
                            process.env.VUE_APP_SERVICE_WORKER_PUSH_PUBLIC_KEY,
                        ),
                    };

                    return registration.pushManager.subscribe(subscribeOptions);
                })
                .then(function (pushSubscription) {
                    _this.onSubscribe('web-push', pushSubscription).then(() => _this.webpush.found = true);
                    _this.refreshWebPush();

                    return pushSubscription;
                })
                .catch(function () {
                    _this.refreshWebPush();
                });
        },
        async disableWebPush() {
            var _this = this;

            var sub = await (await navigator.serviceWorker.ready).pushManager.getSubscription();

            await _this.onUnsubscribe('web-push', {endpoint: sub.endpoint});

            _this.webpush.found = false;
            _this.refreshWebPush()
        },
        initWebPush() {
            var _this = this;

            this.refreshWebPush()

            navigator.serviceWorker.ready
                .then(
                    (sw) => sw.pushManager.getSubscription(),
                )
                .then((sub) => _this.locate('web-push', {endpoint: sub.endpoint}))
                .then(
                    () => _this.webpush.found = true,
                    (resp) => (_this.webpush.found = !(resp && 'found' in resp) ? null : resp.found),
                )
        },
        async enableSMS(modal) {
            modal.preventDefault();

            if(!this.sms_state) {
                return
            }

            var _this = this;

            this.onSubscribe('twilio', {to: this.sms.to}).then(
                () => {
                    _this.sms.found = true
                    _this.$bvModal.hide('enable-sms')
                },
                (errors) => { console.log(errors); _this.sms.errors = errors; },
            )
        },
        async disableSMS() {
            await this.onUnsubscribe('twilio');

            this.sms.found = false;

            this.$bvModal.hide('enable-sms')
        },
        initSMS() {
            var _this = this;

            this.locate('twilio').then(
                () => _this.sms.found = true,
                (resp) => (_this.sms.found = !(resp && 'found' in resp) ? null : resp.found),
            )
        }
    },
    mounted() {
        this.initWebPush()
        this.initSMS()
    }
  };
  </script>