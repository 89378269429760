import Settings from './Settings.vue'
import SettingsModify from './SettingsModify.vue'
import Events from './Events.vue'
import Event from './Event.vue'
import Organizations from './Organizations.vue'
import Organization from './Organization.vue'
import OrganizationGroup from './OrganizationGroup.vue'

export default {
	routes: [{
		name: 'account/settings',
		path: '/account/settings',
		component: Settings
	}, {
		name: 'account/settings/modify',
		path: '/account/settings/modify',
		component: SettingsModify
	}, {
		name: 'account/events',
		path: '/events',
		component: Events
	}, {
		name: 'account/event',
		path: '/events/:event_id',
		component: Event
	}, {
		name: 'account/organizations',
		path: '/organizations',
		component: Organizations
	}, {
		name: 'account/organization',
		path: '/organizations/:organization_id',
		component: Organization
	}, {
		name: 'account/organization/group',
		path: '/organizations/:organization_id/groups/:group_id',
		component: OrganizationGroup
	}]
}