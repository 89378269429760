<template>
  <b-container
    id="subnav"
    v-if="logged_in && on_user_page"
    class="d-none d-lg-block"
  >
    <b-row>
      <b-col style="text-align: left">
        <b-button variant="light" :to="{ name: 'account/settings' }"
          >My Account</b-button
        >
        <b-button variant="light" :to="{ name: 'account/events' }"
          >Events</b-button
        >
      </b-col>
      <b-col style="text-align: right">
        <b-button variant="light" :to="{ name: 'account/organizations' }"
          >Organizations</b-button
        >
        <b-button variant="light" :to="{ name: 'admin/members' }">Members</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserSubNav",
  computed: {
    on_user_page() {
      return this.$route.name.startsWith("account") || this.$route.name.startsWith("admin");
    },
    ...mapGetters(["logged_in"]),
  },
};
</script>