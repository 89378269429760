<template>
  <div v-if="logged_in">
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="6" align-self="center">
        <b-card v-if="!org_edit.enabled">
          <b-card-title>
            Organization
            <b-button-group v-if="organization" class="float-right">
              <b-button variant="light" size="sm" @click="enable_org_edit">
                Modify
              </b-button>
            </b-button-group>
          </b-card-title>
          <b-table v-if="organization_found" stacked :items="[organization]" :fields="org_headers">
            <template #cell(created_at)="data">
              <HumanDate :date="data.value" />
            </template>
          </b-table>
          <b-container v-else>
            <span v-if="organization_loading">Loading...</span>
            <h4 v-else class="text-center">Not Found</h4>
          </b-container>
        </b-card>
        <b-card v-else>
          <b-form @submit="submitOrgEdit">
            <b-card-title>
              Organization
              <b-button-group class="float-right">
                <b-button type="submit" variant="info" size="sm">
                  Save
                </b-button>
                <b-button variant="danger" size="sm" @click="disable_org_edit">
                  Cancel
                </b-button>
              </b-button-group>
            </b-card-title>
            <b-table-simple>
              <b-tbody>
                <b-tr>
                  <b-th>Name</b-th>
                  <b-td>
                    <b-form-input id="org_edit_name" v-model="org_edit.name" placeholder="Enter Name"
                      required></b-form-input>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th>Created</b-th>
                  <b-td>
                    <HumanDate :date="organization.created_at" />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="10" align-self="center">
        <b-card>
          <b-card-title>
            Members
            <b-button-group v-if="organization != null" class="float-right">
              <OrganizationAddMember :organization="organization" size="sm" text="Add Member"
              v-bind:open="loadMembers" v-bind:submit="submitAddMember"></OrganizationAddMember>
              <OrganizationCreateEvent variant="warning" :organization="organization" size="sm" text="Create Event"
              v-bind:submit="createEvent"></OrganizationCreateEvent>
            </b-button-group>
          </b-card-title>
          <b-list-group v-if="users && users.length != 0">
            <div class="d-flex w-100 justify-content-between" v-for="user in users" :key="user.id">
              <ConfirmationModal :modal_id="user.id" variant="outline-danger" squared size="sm" @confirm.prevent="removeMember(user)">
                <template #button>X</template>
                <template #title>Remove user?</template>
                <template #body>
                  Are you sure you want to remove <b-badge>{{ user.name }}</b-badge>?
                </template>
              </ConfirmationModal>
              <span style="width: 100%" class="list-group-item d-flex justify-content-between align-items-center">
                {{ user.name }}
                <span>
                  <b-badge :variant="user.level == 'admin' ? 'primary' : 'secondary'" pill>
                    {{ user.level }}
                  </b-badge>
                </span>
              </span>
              <b-button variant="outline-info" v-b-modal="'events-' + user.id" squared size="sm">Answers</b-button>
              <OrganizationMemberAnswers :modal_id="'events-' + user.id" :user="user" size="sm" text="View" v-bind:open="loadMemberAnswers"></OrganizationMemberAnswers>
            </div>
          </b-list-group>
          <b-container v-else>
            <span v-if="organization_loading">Loading...</span>
            <h4 v-else class="text-center">No members</h4>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="10" align-self="center">
        <b-card>
          <b-card-title>
            Groups
            <b-button-group class="float-right">
              <NewGroupPrompt size="sm" text="Create" v-bind:submit="submitNewGroup"></NewGroupPrompt>
            </b-button-group>
          </b-card-title>
          <b-list-group v-if="groups && groups.length != 0">
            <div class="d-flex w-100 justify-content-between" v-for="group in groups" :key="group.id">
              <ConfirmationModal :modal_id="group.id" variant="outline-danger" squared size="sm" @confirm.prevent="removeGroup(group)">
                <template #button>X</template>
                <template #title>Remove group?</template>
                <template #body>
                  Are you sure you want to remove <b-badge>{{ group.name }}</b-badge>?
                </template>
              </ConfirmationModal>
              <b-link :to="{
                name: 'account/organization/group',
                params: {
                  organization_id: organization.id,
                  group_id: group.id,
                },
              }" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                {{ group.name }}
                <span>
                  <b-badge v-if="group.users && group.users.length != 0" variant="primary" pill>users:{{
                    group.users.length
                    }}</b-badge>
                  <b-badge v-else variant="light" pill>users:0</b-badge>
                  &nbsp;
                  <b-badge v-if="group.questions && group.questions.length != 0" variant="primary" pill>questions:{{
                    group.questions.length }}</b-badge>
                  <b-badge v-else variant="light" pill>questions:0</b-badge>
                </span>
              </b-link>
              <UpdateGroup :group="group" variant="outline-warning" squared size="sm" @save="event => updateGroup(event)">
              </UpdateGroup>
            </div>
          </b-list-group>
          <b-container v-else>
            <span v-if="organization_loading">Loading...</span>
            <h4 v-else class="text-center">No groups</h4>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HumanDate from "../../components/HumanDate.vue";
import NewGroupPrompt from "../../components/NewGroupPrompt.vue";
import OrganizationMemberAnswers from "../../components/OrganizationMemberAnswers.vue";
import OrganizationAddMember from "../../components/OrganizationAddMember.vue";
import OrganizationCreateEvent from "../../components/OrganizationCreateEvent.vue";
import ConfirmationModal from "../../components/ConfirmationModal.vue";
import UpdateGroup from "../../components/UpdateGroup.vue";

export default {
  name: "AccountOrganization",
  data() {
    return {
      organization: null,
      org_edit: {
        enabled: false,
        name: null,
      },
      org_headers: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "created_at",
          label: "Created",
        },
      ],
      users: null,
      groups: null,
      group_users: null,
      group_headers: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "users",
          label: "Participants",
        },
        {
          key: "questions",
          label: "Questions",
        },
        {
          key: "created_at",
          label: "Created",
        },
      ],
      organization_loading: true,
    };
  },
  methods: {
    async fetchOrganization() {
      if (this.logged_in) {
        var organization = await this.user.organization(
          this.$route.params.organization_id,
          {
            params: {
              include: ["users", "groups", "groups.users", "groups.questions"],
            },
          }
        );
        if (!organization.id) {
          this.organization_loading = false;
          return;
        }
        this.organization = organization;
        this.users = organization._loaded_users;
        this.groups = organization._loaded_groups;
        return this.organization;
      }
    },
    async submitNewGroup(new_group) {
      return new Promise((resolve, reject) => {
        this.organization
          .createGroup(new_group)
          .then(() => {
            this.fetchOrganization().then(resolve);
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
    },
    async loadMemberAnswers(user_id) {
      return this.organization.userAnswers(user_id)
    },
    async loadMembers() {
      return new Promise((resolve, reject) => {
        this.api.v1.admin.users.all()
          .then((users) => {
            var user_emails = [];
            for (var j = 0; j < users.length; j++) {
              user_emails.push(users[j].email);
            }
            resolve(user_emails);
          })
          .catch(reject);
      });
    },
    async submitAddMember(result) {
      return new Promise((resolve, reject) => {
        this.organization
          .invite(result.member, result.level)
          .then((user) => {
            this.fetchOrganization();
            resolve(user);
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
    },
    removeMember(user) {
      this.organization
        .removeMember(user.id)
        .then(
          () => {
            this.$bvModal.hide(user.id);

            this.fetchOrganization();
          },
          (errors) => {
            console.log(errors);
          }
        )
    },
    async createEvent() {
      return new Promise((resolve, reject) => {
        this.organization
          .createEvent()
          .then((event) => {
            this.fetchOrganization();
            resolve(event);
          })
          .catch((err) => {
            reject(err.msg);
          });
      });
    },
    enable_org_edit() {
      if (this.organization == null || this.organization_loading) {
        return;
      }
      this.org_edit.name = this.organization.name;
      this.org_edit.enabled = true;
    },
    disable_org_edit() {
      this.org_edit.enabled = false;
    },
    org_edit_values() {
      return {
        name: this.org_edit.name,
      };
    },
    async submitOrgEdit() {
      return new Promise((resolve, reject) => {
        this.organization
          .update(this.org_edit_values())
          .then(
            () => this.fetchOrganization(),
            (err) => reject(err.msg)
          )
          .then(() => { this.disable_org_edit(); resolve() })
      });
    },
    removeGroup(group) {
      this.organization
        .removeGroup(group.id)
        .then(
          () => {
            this.$bvModal.hide(group.id);

            this.fetchOrganization();
          },
          (errors) => {
            console.log(errors);
          }
        )
    },
    updateGroup(event) {
      this.organization
        .groupUpdate(event.group.id, event.updates)
        .then(
          () => {
            event.hide()
            return this.fetchOrganization();
          },
          (errors) => event.errors(errors)
        )
    },
    init() {
      this.fetchOrganization().then(() => {
        this.organization_loading = false;
      });
    },
  },
  computed: {
    organization_found() {
      return this.organization != null;
    },
    ...mapGetters(["api", "user", "logged_in"]),
  },
  components: { HumanDate, NewGroupPrompt, OrganizationMemberAnswers, OrganizationAddMember, OrganizationCreateEvent, ConfirmationModal, UpdateGroup },
  mounted() {
    window.addEventListener('introspectator:userLoaded', this.init)

    if(this.logged_in) {
      this.init();
    }
  },
  destroyed() {
    window.removeEventListener('introspectator:userLoaded', this.init)
  }
};
</script>