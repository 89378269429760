<template>
  <div>
    <b-button :variant="variant" :size="size" v-b-modal="modal_id">{{
      text || "Create Event"
    }}</b-button>
    <b-modal
      :id="modal_id"
      ref="organization_create_event"
      :title="title || 'Create Event for ' + organization.name"
      @ok="handleOk"
    >
      <b-alert v-if="submit_error" show variant="danger"
        >Error: {{ error }}</b-alert
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "OrganizationCreateEvent",
  props: ["organization", "variant", "size", "submit", "text", "title"],
  data() {
    return {
      error: null,
    };
  },
  computed: {
    modal_id() {
      return "organization-create-event-" + this.organization.id;
    },
    submit_error() {
      return (this.error && this.error != "") || false;
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.submit()
        .then(() => {
          this.error = null;
          this.$nextTick(() => {
            this.$bvModal.hide(this.modal_id);
          });
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
};
</script>
  