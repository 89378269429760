<template>
  <div>
    <b-button variant="info" :size="size" v-b-modal="modal_id">{{
      text || "Answer Question"
    }}</b-button>
    <b-modal
      :id="modal_id"
      ref="modal"
      :title="title || 'Answer: ' + question.question"
      @ok="handleOk"
    >
      <form ref="answer_question" @submit.stop.prevent="handleSubmit">
        <QuestionInput ref="question_input" :question="question"></QuestionInput>
      </form>
      <b-alert v-for="error in errors" :key="error" show variant="danger">
        {{ error }}
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import QuestionInput from './QuestionInput.vue';

export default {
  name: "AnswerQuestion",
  props: ["question", "answer_id", "size", "submit", "text", "title"],
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    modal_id() {
      return "answer-question-" + this.answer_id;
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.$refs.question_input.valid) {
        return;
      }

      this.submit({
        question: this.question,
        answer_id: this.answer_id,
        value: this.$refs.question_input.value,
      })
      .then(
          () => {
            this.errors = [];
            this.$nextTick(() => this.$bvModal.hide("answer-question"));
          },
          (errs) => this.errors = errs
      );
    },
  },
  components: {QuestionInput}
};
</script>