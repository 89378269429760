<template>
  <div>
    <b-button variant="info" :size="size" v-b-modal.create-group-prompt>{{
      text || "Create Group"
    }}</b-button>
    <b-modal
      id="create-group-prompt"
      ref="modal"
      title="Create Group"
      @show="resetNewGroup"
      @hidden="resetNewGroup"
      @ok="handleOk"
    >
      <form ref="create_group" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="group-name"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="group-name"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <b-alert v-if="submit_error" show variant="danger"
        >Error: {{ error }}</b-alert
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "NewGroupPrompt",
  props: ["submit", "size", "text"],
  data() {
    return {
      name: "",
      nameState: null,
      error: null,
    };
  },
  computed: {
    submit_error() {
      return (this.error && this.error != "") || false;
    },
  },
  methods: {
    checkFormValidity() {
      this.nameState = this.name != "";
      return this.nameState;
    },
    resetNewGroup() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    getGroup() {
      var group = {
        name: this.name,
      };

      return group;
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.submit(this.getGroup())
        .then(() => {
          this.error = null;
          this.$nextTick(() => {
            this.$bvModal.hide("create-group-prompt");
          });
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
};
</script>