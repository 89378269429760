function stripSlashes(s) {
	return s.replace(/^\/+|\/+$/g, "")
}

export class IntroSpectator {
	constructor(base_uri) {
		this.base_uri = base_uri || 'https://api.introspectator.com';
		this.v1 = new APIv1Controller(this);
	}

	build_url(path, prefix, params) {
		var parts = [this.base_uri];
		if (prefix) {
			parts.push(prefix);
		}
		if (Array.isArray(path)) {
			parts = parts.concat(path);
		} else {
			parts.push(path)
		}
		var new_url = parts.map((i) => stripSlashes(i)).join('/');
		if (params && params.length != 0) {
			new_url += '?' + Object.keys(params).map((k) => k + "=" + encodeURI(params[k])).join("&")
		}
		return new_url
	}
	_request(method, options, callback) {
		var url = this.build_url(options.path, options.prefix, options.params);
		var xmlhttp = window.XMLHttpRequest ? new XMLHttpRequest() : new window.ActiveXObject("Microsoft.XMLHTTP");
		xmlhttp.open(method, url, true);
		var headers = {
			"Accept": "application/json",
		}
		var body = null;
		if (options.json) {
			headers['Content-Type'] = 'application/json';
			body = JSON.stringify(options.json);
		}
		if ('headers' in options) {
			for (var header in options.headers) {
				headers[header] = options.headers[header];
			}
		}
		for (var header2 in headers) {
			xmlhttp.setRequestHeader(header2, headers[header2]);
		}
		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState == 4) {
				callback(xmlhttp);
			}
		}
		xmlhttp.send(body);
	}
	async request(method, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._request(method, options, resolve);
		});
	}
	_request_json(method, options, callback) {
		this._request(method, options, function (response) {
			var obj = JSON.parse(response.responseText);
			if (options.key) {
				callback(obj[options.key]);
				return
			}
			callback(obj);
		});
	}
	async request_json(method, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._request_json(method, options, resolve);
		});
	}
}

function merge_options(a, b) {
	if (b === undefined || b === null || !b) {
		b = {}
	}
	for (var key in a) {
		if (!(key in b)) {
			b[key] = a[key]
		}
	}
	return b
}

class BaseController {
	constructor(svc) {
		this.svc = svc;
		this.headers = {};
		this.prefix = null;
	}
	_modifyRequest(options) {
		var headers = Object.assign(options.headers || {}, this.headers);
		options.headers = headers;
		options.prefix = options.prefix || this.prefix;

	}
	_request(method, options, callback) {
		this._modifyRequest(options)
		this.svc._request(method, options, callback);
	}
	async request(method, options) {
		this._modifyRequest(options)
		return this.svc.request(method, options);
	}
	_request_json(method, options, callback) {
		this._modifyRequest(options)
		this.svc._request_json(method, options, callback);
	}
	async request_json(method, options) {
		this._modifyRequest(options)
		return this.svc.request_json(method, options);
	}
}

class BaseV1Controller extends BaseController {
	constructor(svc, api) {
		super(svc);
		this.api = api;
		this.prefix = '/v1';
	}
	_modifyRequest(options) {
		super._modifyRequest(options);
		if (this.api.token) {
			let token = ""

			if (typeof this.api.token === 'function') {
				token = this.api.token()
			} else if (typeof this.api.token === 'string') {
				token = this.api.token
			} else {
				console.log("Unexpected token type: " + (typeof this.api.token))
			}

			if (token) {
				options.headers = Object.assign(options.headers || {}, {
					"Authorization": "Bearer " + this.api.token,
				});
			}
		}
	}
}

class APIv1Controller extends BaseV1Controller {
	constructor(svc) {
		super(svc);
		this.api = this;
		this.token = null;
		this.users = new V1UserController(svc, this);
		this.admin = new V1AdminController(svc, this);
		this.user = null;
	}
	_login(token, options, callback) {
		this.token = token;
		var _this = this;
		this.users._get(options, function (user) {
			_this.user = user;
			callback(user);
		})
	}
	async login(token, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._login(token, options, resolve);
		});
	}
}

class V1UserController extends BaseV1Controller {
	constructor(svc, api) {
		super(svc, api);
	}
	_get(options, callback) {
		var _this = this;
		this._request_json('GET', merge_options({
			path: '/user'
		}, options), function (raw_user) {
			callback(new V1User(_this.svc, _this.api, raw_user));
		});
	}
	async get(options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._get(options, resolve);
		})
	}
}

class V1User {
	constructor(svc, api, raw_user) {
		this._svc = svc;
		this._api = api;

		this.id = raw_user.id;
		this.name = raw_user.name;
		this.email = raw_user.email;
		this.level = null;
		this.created_at = raw_user.created_at;
		this.updated_at = raw_user.updated_at;
		this.deleted_at = raw_user.deleted_at;

		if (raw_user.pivot && raw_user.pivot.level) {
			this.level = raw_user.pivot.level;
		}

		this._loaded_events = null;
		this._loaded_questions = null;
		this._loaded_organizations = null;
	}
	_update(values, options, resolve, reject) {
		var _this = this;
		this._api._request_json('PATCH', merge_options({
			path: '/user',
			json: values
		}, options), function (resp) {
			if ("errors" in resp) {
				reject(resp.errors);
				return
			}
			resolve(new V1User(_this._svc, _this._api, resp));
		});
	}
	async update(values, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._update(values, options, resolve, reject);
		})
	}
	_events(options, callback) {
		this._api._request_json('GET', merge_options({
			path: '/user/events',
			key: 'events'
		}, options), callback);
	}
	async events(forcereload, options) {
		var _this = this;
		if (this._loaded_events && !forcereload) {
			return new Promise(function (resolve) {
				return resolve(_this._loaded_events);
			})
		}
		return new Promise(function (resolve) {
			_this._events(options, function (events) {
				_this._loaded_events = events.map((e) => new V1Event(_this._svc, _this._api, _this, e));
				resolve(_this._loaded_events);
			})
		})
	}
	_event(id, options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/event', id]
		}, options), callback);
	}
	async event(id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._event(id, options, function (event) {
				resolve(new V1Event(_this._svc, _this._api, _this, event))
			});
		})
	}
	_questions(options, callback) {
		this._api._request_json('GET', merge_options({
			path: '/user/questions',
			key: 'questions'
		}, options), callback);
	}
	async questions(forcereload, options) {
		var _this = this;
		if (this._loaded_questions && !forcereload) {
			return new Promise(function (resolve) {
				return resolve(_this._loaded_questions);
			})
		}
		return new Promise(function (resolve) {
			_this._questions(options, function (questions) {
				_this._loaded_questions = questions;
				resolve(_this._loaded_questions);
			})
		})
	}
	_organizations(options, callback) {
		this._api._request_json('GET', merge_options({
			path: '/user/organizations',
			key: 'organizations'
		}, options), callback);
	}
	async organizations(forcereload, options) {
		var _this = this;
		if (this._loaded_organizations && !forcereload) {
			return new Promise(function (resolve) {
				return resolve(_this._loaded_organizations);
			})
		}
		return new Promise(function (resolve) {
			_this._organizations(options, function (organizations) {
				_this._loaded_organizations = organizations.map((e) => new V1Organization(_this._svc, _this._api, _this, e));
				resolve(_this._loaded_organizations);
			})
		})
	}
	_organization(id, options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/organization', id]
		}, options), callback);
	}
	async organization(id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._organization(id, options, function (organization) {
				resolve(new V1Organization(_this._svc, _this._api, _this, organization))
			});
		})
	}
	_enableNotifications(handler, data, options, callback) {
		this._api._request_json('POST', merge_options({
			path: '/user/subscribers',
			json: {
				handler: handler,
				metadata: data,
			},
		}, options), callback);
	}
	async enableNotifications(handler, data, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._enableNotifications(handler, data, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors)
						return
					}

					resolve(data)
				}
				reject("An unknown error occurred.")
			});
		})
	}
	_disableNotifications(handler, data, options, callback) {
		this._api._request_json('DELETE', merge_options({
			path: '/user/subscribers',
			json: {
				handler: handler,
				metadata: data,
			},
		}, options), callback);
	}
	async disableNotifications(handler, data, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._disableNotifications(handler, data, options, resolve);
		})
	}
	_locateSubscription(handler, data, options, callback) {
		this._api._request_json('POST', merge_options({
			path: '/user/subscribers/locate',
			json: {
				handler: handler,
				metadata: data,
			},
		}, options), callback);
	}
	async locateSubscription(handler, data, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._locateSubscription(handler, data, options, (data) => {
				if (data && data.found) {
					resolve(data);
				} else {
					reject(data);
				}
			});
		})
	}
}

class V1Event {
	constructor(svc, api, user, raw_event) {
		this._svc = svc;
		this._api = api;

		this.id = raw_event.id;
		this.user = user;
		this.created_at = raw_event.created_at;
		this.updated_at = raw_event.updated_at;
		this.deleted_at = raw_event.deleted_at;

		this._loaded_answers = null;

		if (raw_event.answers) {
			this._loaded_answers = raw_event.answers;
		}
	}
	_answers(options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/event', this.id, 'answers'],
			key: 'answers'
		}, options), callback);
	}
	async answers(options) {
		var _this = this;
		if (this._loaded_answers) {
			return new Promise(function (resolve) {
				return resolve(_this._loaded_answers);
			})
		}
		return new Promise(function (resolve) {
			_this._answers(options, function (answers) {
				_this._loaded_answers = answers;
				resolve(answers);
			})
		})
	}
	_answer(id, value, options, callback) {
		this._api._request_json('PATCH', merge_options({
			path: ['/user/event', this.id, 'answer', id],
			json: {
				value: value,
			}
		}, options), callback);
	}
	async answer(id, value, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._answer(id, value, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors)

						return
					}

					resolve(data)
					return
				}

				reject("An unknown error occurred.")
			})
		})
	}
}

class V1Organization {
	constructor(svc, api, user, raw_organization) {
		this._svc = svc;
		this._api = api;

		this.id = raw_organization.id;
		this.user = user;
		this.name = raw_organization.name;
		this.level = undefined;
		if (raw_organization.pivot) {
			this.level = raw_organization.pivot.level;
		}
		this.created_at = raw_organization.created_at;
		this.updated_at = raw_organization.updated_at;
		this.deleted_at = raw_organization.deleted_at;

		this._loaded_users = null;
		this._loaded_groups = null;

		if (raw_organization.users) {
			this._loaded_users = raw_organization.users.map((u) => new V1User(this.svc, this.api, u));
		}

		if (raw_organization.groups) {
			this._loaded_groups = raw_organization.groups;
		}
	}
	_update(values, options, resolve, reject) {
		var _this = this;
		this._api._request_json('PATCH', merge_options({
			path: ['/user/organization', this.id],
			json: values
		}, options), function (resp) {
			if ("errors" in resp) {
				reject(resp.errors);
				return
			}
			resolve(new V1Organization(_this._svc, _this._api, resp));
		});
	}
	async update(values, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._update(values, options, resolve, reject);
		})
	}
	_createEvent(options, callback) {
		this._api._request_json('POST', merge_options({
			path: ['/user/organization', this.id, 'events']
		}, options), callback);
	}
	async createEvent(options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._createEvent(options, function (event) {
				resolve(event)
			});
		})
	}
	_invite(user_email, level, options, callback) {
		this._api._request_json('POST', merge_options({
			path: ['/user/organization', this.id, 'invite'],
			json: {
				'email': user_email,
				'level': level
			}
		}, options), callback);
	}
	async invite(user_email, level, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._invite(user_email, level, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors[0])
						return
					}
					resolve(data)
					return
				}
				reject("An unknown error occurred.")
			});
		})
	}
	_removeMember(user_id, options, callback) {
		this._api._request_json('DELETE', merge_options({
			path: ['/user/organization', this.id, 'member', user_id],
		}, options), callback);
	}
	async removeMember(user_id, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._removeMember(user_id, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors)
						return
					}

					resolve(data)

					return
				}

				reject("An unknown error occurred.")
			});
		})
	}
	_users(options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/organization', this.id, 'users'],
			key: 'users'
		}, options), callback);
	}
	async users(forcereload, options) {
		var _this = this;
		if (this._loaded_users && !forcereload) {
			return new Promise(function (resolve) {
				return resolve(_this._loaded_users);
			})
		}
		return new Promise(function (resolve) {
			_this._users(options, function (users) {
				_this._loaded_users = users.map((u) => new V1User(_this.svc, _this.api, u));
				resolve(_this._loaded_users);
			})
		})
	}
	_userAnswers(user_id, options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/organization', this.id, 'user', user_id, 'answers'],
			key: 'events'
		}, options), callback);
	}
	async userAnswers(user_id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._userAnswers(user_id, options, function (events) {
				resolve(events.map((e) => new V1Event(_this.svc, _this.api, _this, e)));
			});
		})
	}
	_group(id, options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/organization', this.id, 'group', id]
		}, options), callback);
	}
	async group(id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._group(id, options, resolve);
		})
	}
	_createGroup(values, options, callback) {
		this._api._request_json('POST', merge_options({
			path: ['/user/organization', this.id, 'groups'],
			json: values
		}, options), callback);
	}
	async createGroup(values, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._createGroup(values, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors[0])
						return
					}
					resolve(data)
					return
				}
				reject("An unknown error occurred.")
			});
		})
	}
	_groupUpdate(id, values, options, callback) {
		this._api._request_json('PATCH', merge_options({
			path: ['/user/organization', this.id, 'group', id],
			json: values
		}, options), callback);
	}
	async groupUpdate(id, values, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._groupUpdate(id, values, options, (result) => {
				if (result && "errors" in result) {
					reject(result.errors[0])
				} else {
					resolve(result)
				}
			});
		})
	}
	_removeGroup(group_id, options, callback) {
		this._api._request_json('DELETE', merge_options({
			path: ['/user/organization', this.id, 'group', group_id],
		}, options), callback);
	}
	async removeGroup(group_id, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._removeGroup(group_id, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors)
						return
					}

					resolve(data)
					return
				}

				reject("An unknown error occurred.")
			});
		})
	}
	_groups(options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/organization', this.id, 'groups'],
			key: 'groups'
		}, options), callback);
	}
	async groups(forcereload, options) {
		var _this = this;
		if (this._loaded_groups && !forcereload) {
			return new Promise(function (resolve) {
				return resolve(_this._loaded_groups);
			})
		}
		return new Promise(function (resolve) {
			_this._groups(options, function (groups) {
				_this._loaded_groups = groups.map((u) => new V1User(_this.svc, _this.api, u));
				resolve(_this._loaded_groups);
			})
		})
	}
	_groupsUsers(options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/organization', this.id, 'groups/users'],
			key: 'users'
		}, options), callback);
	}
	async groupsUsers(options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._groupsUsers(options, function (data) {
				if ("errors" in data && data.errors.length != 0) {
					reject(data.errors[0]);
					return
				}
				resolve(data);
			})
		})
	}
	_groupUserAnswers(group_id, user_id, options, callback) {
		this._api._request_json('GET', merge_options({
			path: ['/user/organization', this.id, 'group', group_id, 'user', user_id, 'answers'],
			key: 'events'
		}, options), callback);
	}
	async groupUserAnswers(group_id, user_id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._groupUserAnswers(group_id, user_id, options, function (events) {
				resolve(events.map((e) => new V1Event(_this.svc, _this.api, _this, e)));
			});
		})
	}
	_inviteToGroup(group_id, user_email, options, callback) {
		this._api._request_json('POST', merge_options({
			path: ['/user/organization', this.id, 'group', group_id, 'invite'],
			json: {
				'email': user_email
			}
		}, options), callback);
	}
	async inviteToGroup(group_id, user_email, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._inviteToGroup(group_id, user_email, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors[0])
						return
					}
					resolve(data)
					return
				}
				reject("An unknown error occurred.")
			});
		})
	}
	_removeGroupMember(group_id, user_id, options, callback) {
		this._api._request_json('DELETE', merge_options({
			path: ['/user/organization', this.id, 'group', group_id, 'member', user_id],
		}, options), callback);
	}
	async removeGroupMember(group_id, user_id, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._removeGroupMember(group_id, user_id, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors)
						return
					}

					resolve(data)
					return
				}

				reject("An unknown error occurred.")
			});
		})
	}
	_createQuestion(options, callback) {
		this._api._request_json('POST', merge_options({
			path: ['/user/organization', this.id, 'questions']
		}, options), callback);
	}
	async createQuestion(options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._createQuestion(options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors[0])
						return
					}
					resolve(data)
					return
				}
				reject("An unknown error occurred.")
			});
		})
	}
	_deleteGroupQuestion(group_id, question_id, options, callback) {
		this._api._request_json('DELETE', merge_options({
			path: ['/user/organization', this.id, 'group', group_id, 'question', question_id]
		}, options), callback);
	}
	async deleteGroupQuestion(group_id, question_id, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._deleteGroupQuestion(group_id, question_id, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors[0])
						return
					}
					resolve(data)
					return
				}
				resolve()
			});
		})
	}
	_updateGroupQuestion(group_id, question_id, values, options, callback) {
		this._api._request_json('PATCH', merge_options({
			path: ['/user/organization', this.id, 'group', group_id, 'question', question_id],
			json: values
		}, options), callback);
	}
	async updateGroupQuestion(group_id, question_id, values, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._updateGroupQuestion(group_id, question_id, values, options, function (data) {
				if (data) {
					if ("errors" in data && data.errors.length != 0) {
						reject(data.errors)
						return
					}

					resolve(data)
					return
				}

				resolve()
			});
		})
	}
}

class V1AdminController extends BaseV1Controller {
	constructor(svc, api) {
		super(svc, api);
		this.prefix = api.prefix + '/admin';
		this.users = new V1AdminUserController(svc, this);
	}
}

class V1AdminUserController extends BaseV1Controller {
	constructor(svc, api) {
		super(svc, api);
	}
	_all(options, callback) {
		this.api._request_json('GET', merge_options({
			path: '/users',
			key: 'users'
		}, options), callback);
	}
	async all(options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._all(options, function (users) {
				resolve(users.map((u) => new V1User(_this.svc, _this.api, u)));
			})
		})
	}
	_find(user_id, options, callback) {
		var _this = this;
		this.api._request_json('GET', merge_options({
			path: ['/user', user_id]
		}, options), function (raw_user) {
			callback(new V1User(_this.svc, _this.api, raw_user));
		});
	}
	async find(user_id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._find(user_id, options, resolve);
		})
	}
	_create(name, email, options, callback) {
		this.api._request_json('POST', merge_options({
			path: '/users',
			json: {
				'name': name,
				'email': email,
			},
		}, options), callback);
	}
	async create(name, email, options) {
		var _this = this;
		return new Promise(function (resolve, reject) {
			_this._create(name, email, options, function (resp) {
				if ("errors" in resp) {
					reject(resp.errors);
					return
				}

				resolve(new V1User(_this.svc, _this.api, resp));
			});
		})
	}
	_getEvents(user_id, options, callback) {
		this.api._request_json('GET', merge_options({
			path: ['/user', user_id, 'events'],
			key: 'events'
		}, options), callback);
	}
	async getEvents(user_id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._getEvents(user_id, options, function (events) {
				resolve(events.map((e) => new V1Event(_this.svc, _this.api, _this, e)));
			})
		})
	}
	_getEvent(user_id, event_id, options, callback) {
		this.api._request_json('GET', merge_options({
			path: ['/user', user_id, 'event', event_id]
		}, options), callback);
	}
	async getEvent(user_id, event_id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._getEvent(user_id, event_id, options, function (event) {
				resolve(new V1Event(_this.svc, _this.api, _this, event))
			});
		})
	}
	_createEvent(user_id, options, callback) {
		this.api._request_json('POST', merge_options({
			path: ['/user', user_id, 'events']
		}, options), callback);
	}
	async createEvent(user_id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._createEvent(user_id, options, function (event) {
				resolve(new V1Event(_this.svc, _this.api, _this, event))
			});
		})
	}
	_getQuestions(user_id, options, callback) {
		this.api._request_json('GET', merge_options({
			path: ['/user', user_id, 'questions'],
			key: 'questions'
		}, options), callback);
	}
	async getQuestions(user_id, options) {
		var _this = this;
		return new Promise(function (resolve) {
			_this._getQuestions(user_id, options, function (questions) {
				resolve(questions);
			})
		})
	}
}