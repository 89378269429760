<template>
  <div class="header">
    <HeaderNavigation></HeaderNavigation>
  </div>
</template>


<script>
import HeaderNavigation from './HeaderNavigation.vue'

export default {
  name: 'AppHeader',
  components: {
    HeaderNavigation
  }
}
</script>
