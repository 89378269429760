import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './styles/index.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

import store from '@/store'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc-ts'

import App from './App.vue'
import Home from './views/Home.vue'
import AboutUs from './views/AboutUs.vue'
import Login from './views/Login.vue'
import AccountConfig from './views/Account/index.js'
import AdminConfig from './views/Admin/index.js'
import OidcConfig from './views/oidc/index.js'

const router = new VueRouter({
	mode: 'history',
	routes: [{
		name: 'home',
		path: '/',
		component: Home
	}, {
		name: 'about-us',
		path: '/about-us',
		component: AboutUs
	}, {
		name: 'login',
		path: '/login',
		component: Login
	},
	...AccountConfig.routes,
	...AdminConfig.routes,
	...OidcConfig.routes
	],
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store))
router.beforeEach((to, _, next) => {
	store.commit('SET_ROUTE', to);
	next();
})

window.app = new Vue({
	router,
	store,
	render: h => h(App),
})

window.app.$mount('#app')
