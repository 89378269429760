<template>
    <div>
      <b-button variant="info" :size="size" v-b-modal.add-member-prompt>{{
        text || "Add Member"
      }}</b-button>
      <b-modal
        id="add-member-prompt"
        ref="modal"
        title="Add Member"
        @show="resetAddMember"
        @hidden="resetAddMember"
        @ok="handleOk"
      >
        <form ref="add_member" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Name"
            label-for="member-name"
            invalid-feedback="Name is required"
            :state="nameState"
          >
            <b-form-input
              id="member-name"
              v-model="name"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="member-email"
            invalid-feedback="Email is required"
            :state="emailState"
          >
            <b-form-input
              id="member-email"
              v-model="email"
              :state="emailState"
              required
            ></b-form-input>
          </b-form-group>
        </form>
        <b-alert v-if="submit_error" show variant="danger">
          <div v-for="error in errors" :key="error">
            {{ error }}
          </div>
        </b-alert>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    name: "AddMember",
    props: ["submit", "size", "text"],
    data() {
      return {
        name: "",
        nameState: null,
        email: "",
        emailState: null,
        errors: null,
      };
    },
    computed: {
      submit_error() {
        return (this.errors && this.errors.length != 0) || false;
      },
    },
    methods: {
      checkFormValidity() {
        this.nameState = this.name != "";
        this.emailState = this.email != "";
        return this.nameState && this.emailState;
      },
      resetAddMember() {
        this.name = "";
        this.nameState = null;
        this.email = "";
        this.emailState = null;
      },
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.handleSubmit();
      },
      handleSubmit() {
        if (!this.checkFormValidity()) {
          return;
        }
  
        this.submit(this.name, this.email)
          .then(() => {
            this.errors = null;
            this.$nextTick(() => {
              this.$bvModal.hide("add-member-prompt");
            });
          })
          .catch((errs) => {
            this.errors = errs;
          });
      },
    },
  };
  </script>