<template>
  <b-container>
    <b-row>
      <b-col cols="6" offset="3">
        <b-card v-if="!logged_in">
          <b-card-body align="center">
            <b-form @submit="onSubmit">
              <b-form-group label="Email" label-for="email" class="grey-text font-weight-light">
                <b-form-input
                  id="email"
                  type="email"
                  v-model="creds.email"
                  placeholder="Enter email"
                  required
                ></b-form-input>
              </b-form-group>


              <b-form-group label="Password" label-for="password" class="grey-text font-weight-light">
                <b-form-input
                  id="password"
                  type="password"
                  v-model="creds.password"
                  placeholder="Enter password"
                  required
                ></b-form-input>
              </b-form-group>

              <div class="text-center py-2 mt-4">
                  <b-button type="submit" variant="dark">Login</b-button>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
        <div v-else>
          You're already logged in {{ username }}.
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  data(){
    return {
      creds: {
        email: "",
        password: "",
      }
    }
},
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      await this.login(this.creds.email);
      if(this.logged_in){
        console.log('Welcome', this.username);
        this.$router.push('/')
      }
    },
    ...mapActions([
      'login'
    ])
  },
  computed: {
    ...mapGetters([
      'username',
      'logged_in'
    ])
  }
}
</script>